import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_TESORERIA);

export default {
  obtener_viaticos(options) {
    return http.get(utils.url_options(`/viaticos`, options));
  },

  obtener_viaticos_por_pagar(options) {
    return http.get(utils.url_options(`/viaticos/por_pagar`, options));
  },

  obtener_viatico(id) {
    return http.get(`/viaticos/${id}`);
  },

  actualizar_estatus(id, payload) {
    return http.put(`/viaticos/${id}/estatus`, payload);
  },

  pagar_viaticos(payload) {
    return http.post(`/viaticos/pagar`, payload);
  },

  obtener_comentarios(id) {
    return http.get(`/viaticos/${id}/comentarios`);
  },

  agregar_comentario(id, payload) {
    return http.post(`/viaticos/${id}/comentarios`, payload);
  },

  subir_documento(id, payload) {
    return http.post(`/viaticos/${id}/documentos`, payload);
  },

  descargar_documento(id, documento) {
    return http.get(`/viaticos/${id}/documentos/${documento}`, {responseType: 'blob'});
  },

  visualizar_documento(viatico, documento) {
    return http.get(`/viaticos/${viatico}/documentos/${documento}`, {responseType: 'arraybuffer'})
  },

  autorizacion_obtener_viaticos(options) {
    return http.get(utils.url_options(`/viaticos/autorizacion`, options));
  },

  crear_comprobante(viatico, payload) {
    return http.post(`/viaticos/${viatico}/conceptos`, payload);
  },

  editar_comprobante(id, comprobante, payload) {
    return http.put(`/viaticos/${id}/comprobantes/${comprobante}`, payload);
  },

  agregar_comentario_concepto(id, comprobante, payload) {
    return http.post(`/viaticos/${id}/comprobantes/${comprobante}/comentarios`, payload);
  },

  agregar_evidencia(id, payload) {
    return http.post(`/viaticos/${id}/evidencias`, payload);
  },

  editar_evidencia(id, evidencia, payload) {
    return http.put(`/viaticos/${id}/evidencias/${evidencia}`, payload);
  },

  eliminar_evidencia(id, evidencia) {
    return http.delete(`/viaticos/${id}/evidencias/${evidencia}`);
  },

  descargar_evidencia_documentos(id, evidencia, documento) {
    return http.get(`/viaticos/${id}/evidencias/${evidencia}/documentos/${documento}`, {responseType: 'arraybuffer'});
  }
}