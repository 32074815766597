<template>
  <div>
    <div class="row mt-3">
      <div class="col-sm-9 viatico-titulo">Evidencias</div>
      <div v-if="viatico.tipo_cobro_sobrante == 2 && estatus_validos(viatico.estatus,['ESTATUS_COBRO_MONTO_SOBRANTE'])" class="col-sm-3 text-right">
        <button class="btn btn-secondary" @click="modal_agregar=true">Agregar evidencia</button>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm-12">
        <table>
          <thead>
            <tr>
              <th>Monto</th>
              <th>Fecha de deposito</th>
              <th>Tipo de pago</th>
              <th>Estatus</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="evidencia in viatico.evidencias" :key="evidencia.id">
              <td>${{ $helper.moneyFormat(evidencia.monto) }}</td>
              <td>{{ evidencia.fecha_deposito }}</td>
              <td>{{ evidencia.tipo == 1 ? 'Depósito' : 'Descuento de nómina' }}</td>
              <td>{{ estatus(evidencia.estatus) }}</td>
              <td>
                <i class="fa-regular fa-folder-open mr-2" title="Revisar evidencia" @click="revisar_evidencia(evidencia)"></i>
                <i v-if="(viatico.tipo_cobro_sobrante == 1 && estatus_validos(viatico.estatus, ['ESTATUS_COBRO_MONTO_SOBRANTE']) && evidencia.estatus == 1)" class="fa-solid fa-trash-can" title="Eliminar comprobante" @click="eliminar_evidencia(evidencia)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Agregar v-if="modal_agregar" :viatico="viatico" @close="modal_agregar=false" @update="actualizar" />
    <Info v-if="modal_info" :viatico="viatico" :evidencia="evidencia" @close="cerrar_revisar_evidencia" @update="actualizar" :catalogo="catalogo"/>
  </div>
</template>

<script>
import config from '@/apps/tesoreria/configuracion/viaticos';

import Agregar from './Evidencia/Agregar'
import Info from './Evidencia/Info'

import api from '@/apps/constelacion/api/tesoreria';

export default {
  components: {
    Agregar, Info
  },
  props: {
    viatico: {
      type: Object,
      default() {
        return {
          evidencias: []
        }
      }
    },
    catalogo:{
      type: Array,
      default(){
        return{
          catalogo:[]
        }
      }
    }
  }
  ,data() {
    return {
      evidencia: null,
      modal_agregar: false,
      modal_info: false
    }
  }
  ,methods: {
    async definit_tipo_pago(tipo) {
      try {
        let payload = this.$helper.clone(this.viatico);
        payload.tipo_cobro_sobrante = tipo;

        await api.editar_viatico(this.viatico.id, payload);

        this.$emit('update');
      }catch(e) {
        this.$log.info('error', e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,actualizar() {
      this.modal_agregar = false;
      this.modal_info = false;
      this.$emit('update');
    }
    ,estatus(estatus) {
      switch(estatus) {
        case 1:
          return 'Activo';
        case 2:
          return 'Aprobado';
        case 100:
          return 'Rechazado';
      }
    }
    ,revisar_evidencia(evidencia) {
      this.modal_info = true;
      this.evidencia = evidencia;
    }
    ,cerrar_revisar_evidencia() {
      this.modal_info = false;
      this.evidencia = null;
    }
    ,async eliminar_evidencia(evidencia) {
      try {
        api.eliminar_evidencia(this.viatico.id, evidencia.id);

        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,estatus_validos(valor, estatus) {
      return config.viaticos.estatus_validos(valor, estatus);
    }
  }
  ,computed: {
    viatico_constantes() {
      return config.viaticos.constantes;
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;

  thead {
    tr {
      th {
        background-color: #6b6b6b;
        color: #fff;
        padding: 5px 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px 10px;
      }
    }

    tr:nth-child(2n+1) {
      background-color: #dddddd;
    }
  }
}
</style>